// import '../topNav.css';
import { Row, Col, Collapse } from 'antd';
// import Header from '../header/header.js';
import Header from '../header/index.js';
import Footer from './footer';
import './Landing.css';
import './footer.css';
import ContactUs from '../home/contactus.js';
import { ImageUrl } from '../../Shared/imageUrlPath.js';
const { Panel } = Collapse;

const AboutUs = () => {
  var ls = localStorage;
  const customPanelStyle = {
    background: '#ffffff',
    borderRadius: 10,
    marginBottom: 24,
    overflow: 'hidden',
  };

  const onChange = (key) => {
    console.log(key);
  };
  return (
    <>
      <Header />
      <Row className="container_img">
        <Col xs={24}>
          <Row className="aboutContainner">
            <Col
              xs={24}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <h1 className="headingAbout">
                  Our Story, our Dream and our Solutions
                </h1>
                <p className="paraAbout">
                  Discover the origin of Sendbiz - The desire to help MSMEs find
                  customers. Since 2019, we have dedicated ourselves to the
                  development of a web platform allowing all companies, whatever
                  their activity, at a lower cost to highlight their values,
                  products, services, etc. and to get noticed by potential
                  customers. This goal can only be achieved if many of you join
                  us on Sendbiz
                </p>
                {/* <Row>
                  <Col xs={12} className="logo_container">
                    <Row>
                      <Col xs={12}>
                        <div className="clientLogoIn">
                          <img
                            style={{
                              width: '60px',
                              height: '60px',
                              left: '25px',
                            }}
                            src={
                              ImageUrl.imageUrlPath + 'about-us/client_img1.png'
                            }
                          />
                          <img
                            className="img_client"
                            style={{
                              width: '60px',
                              height: '60px',
                              left: '25px',
                            }}
                            src={
                              ImageUrl.imageUrlPath + 'about-us/client_img2.png'
                            }
                          />
                          <img
                            className="img_client"
                            style={{
                              width: '60px',
                              height: '60px',
                              left: '50px',
                            }}
                            src={
                              ImageUrl.imageUrlPath + 'about-us/client_img3.png'
                            }
                          />
                          <img
                            className="img_client"
                            style={{
                              width: '60px',
                              height: '60px',
                              left: '75px',
                            }}
                            src={
                              ImageUrl.imageUrlPath + 'about-us/client_img4.png'
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <h4 className="boxText">36k+ Happy Clients</h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} />
                  <Col
                    xs={11}
                    className="logo_container"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className="clientLogo">
                      <div>
                        <img
                          src={ImageUrl.imageUrlPath + 'about-us/img1.png'}
                          className="image1"
                        />
                      </div>
                      <div>
                        <h4 className="boxText">130+ New jobs Everyday!</h4>
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </div>
            </Col>
            <Col xs={0} sm={2} />
            <Col xs={24} sm={10}>
              <img
                src={ImageUrl.imageUrlPath + 'about-us/banner_aboutus.png'}
                alt="banner_aboutus"
                className="image1"
              />
            </Col>
            <Col xs={24} style={{ paddingTop: '80px' }}>
              <Row>
                <Col xs={24} sm={10}>
                  <p className="text_about">About Sendbiz</p>
                  <h4 className="text_about1">
                    Our Story: Unveiling Our Passionate Journey
                  </h4>
                </Col>
                <Col xs={24} sm={14}>
                  <div className="text_about2">
                    Sendbiz is a platform that allows micro, small and medium
                    sized companies to present information, jobs , products /
                    service and events, they offer in an ergonomic way.
                    Worldwide, we give great visibility to millions of companies
                    who can promote themselves, recruit , sell their products /
                    services.
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              style={{
                padding: '48px 0px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: '700px', maxHeight: '700px' }}>
                <video
                  controls
                  poster={
                    ImageUrl.imageUrlPath + 'about-us/aboutusVidCover.jpg'
                  }
                  className="image1"
                >
                  <source
                    src={ImageUrl.imageUrlPath + 'about-us/aboutUsVideo.mp4'}
                    type="video/mp4"
                  />
                </video>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="container_img2">
        <Col xs={24} sm={14} className="text_about">
          {' '}
          <p className="text_about">About Sendbiz</p>
          <h4 className="text_about3">
            Our Story: Unveiling Our Passionate Journey
          </h4>
        </Col>
        <Col xs={0} sm={10} />
        <Col xs={24} style={{ padding: '40px 0px' }}>
          <Row>
            <vl className="verticalLine" />
            <Col xs={8} sm={4} style={{ padding: '0px 10px' }}>
              <h5 className="text_about4">Alexis</h5>
              <span className="text_para">Founder</span>
            </Col>
            <vl className="verticalLine" />
            <Col xs={8} sm={4} style={{ padding: '0px 10px' }}>
              <h5 className="text_about4">2020</h5>
              <span className="text_para">Creation</span>
            </Col>
            <vl className="verticalLine" />
            <Col xs={7} sm={4} style={{ padding: '0px 10px' }}>
              <h5 className="text_about4">2024</h5>
              <span className="text_para">Launch</span>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} style={{ paddingTop: '30px' }}>
              <p className="text_para">
                Alexis, the founder of sendbiz, has more than 10 years of
                experience in entrepreneurship in small and medium-sized
                businesses and his experience led him to create Sendbiz to help
                build on his experience a platform to help MSMEs develop their
                business and grow their notoriety
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={24}></Col>
      </Row>
      <Row className="aboutContainner">
        <Col xs={24}>
          <Col xs={24}>
            <p className="text_about">Sendbiz Caters</p>
          </Col>
          <Row>
            <Col xs={24} sm={14}>
              <h2 className="heading2">
                How can Sendbiz <br />
                help you ?
              </h2>
            </Col>
            <Col xs={24} sm={10} className="para3">
              <p>
                Explore our FAQ section to find answers to commonly asked
                queries. From product inquiries to billing details, we've got
                you covered.{' '}
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={24} style={{ padding: '56px 0px' }}>
          <Collapse onChange={onChange} style={customPanelStyle}>
            <Panel
              header={<b>What Type of Business Can Use Sendbiz?</b>}
              key="1"
            >
              <p>
                At Sendbiz, we understand the unique challenges faced by micro,
                small, and medium-sized enterprises. Our platform is designed to
                provide comprehensive support, ensuring your business not only
                survives but thrives. Here's how Sendbiz can make a significant
                difference for your company: <br />
                1. Increase Visibility <br />
                •Global Reach: Present your company to a worldwide audience
                without any commission fees.
                <br />
                •Enhanced Profile: Create a compelling company page complete
                with images and videos to attract attention. 2. Expand Your
                Network <br />
                •Business Connections: Connect with potential clients, partners,
                and subcontractors from around the globe. <br />
                •Direct Communication: Engage directly with other companies and
                professionals to build valuable relationships.
                <br />
                3. Streamline Recruitment <br />
                •Job Listings: Advertise job vacancies and manage applications
                seamlessly. <br />
                •Talent Search: Find and contact skilled professionals and
                subcontractors effortlessly. <br />
                4. Promote Your Events <br />
                •Event Hosting: Host and promote your business events to a broad
                audience. <br />
                •Increased Engagement: Ensure maximum participation and
                interaction at your events. <br />
                5. Comprehensive Business Solutions <br />
                •Service Promotion: Advertise your services and products
                effectively. <br />
                •Search Capabilities: Utilize advanced search functions to find
                companies, services, products, and job opportunities that meet
                your needs. <br />
              </p>
            </Panel>
          </Collapse>
          <Collapse onChange={onChange} style={customPanelStyle}>
            <Panel
              header={
                <b>What are the benefits of using Sendbiz for my business?</b>
              }
              key="1"
            >
              <p>
                Sendbiz offers numerous benefits, including: <br />
                •Global Visibility: Showcase your company to an international
                audience. <br />
                •Networking Opportunities: Connect with potential clients,
                partners, and subcontractors. <br />
                •Recruitment Tools: Post job vacancies and manage applications
                seamlessly.
                <br />
                •Event Promotion: Advertise your business events to a broad
                audience.
                <br />
              </p>
            </Panel>
          </Collapse>
          <Collapse onChange={onChange} style={customPanelStyle}>
            <Panel
              header={<b>How can I create a page for my company on Sendbiz?</b>}
              key="1"
            >
              <p>
                Creating a company page on Sendbiz is simple and free. Follow
                these steps: <br />
                1.Go to this link to activate your company page.
                <br />
                2.Fill in your company details, including name, description, and
                contact information.
                <br />
                3.Upload images and videos to enhance your company profile.
                <br />
                4.Start promoting your services, products, job offers, and
                events.
                <br />
              </p>
            </Panel>
          </Collapse>
          <Collapse onChange={onChange} style={customPanelStyle}>
            <Panel
              header={
                <b>How can I promote my products and services on Sendbiz?</b>
              }
              key="1"
            >
              <p>
                To promote your products and services on Sendbiz: <br />
                1.Create and complete your company page with detailed
                descriptions, images, and videos.
                <br />
                2.Use the platform to list your services and products.
                <br />
                3.Post special offers and events to attract more customers.
                <br />
                4.Utilize advanced search and networking features to reach a
                wider audience
                <br />
              </p>
            </Panel>
          </Collapse>
          <Collapse onChange={onChange} style={customPanelStyle}>
            <Panel
              header={<b>How can I manage job applications on Sendbiz?</b>}
              key="1"
            >
              <p>
                Sendbiz provides robust recruitment tools to help you manage job
                applications: <br />
                1.Post your job vacancies on your company page.
                <br />
                2.Receive and review applications through your Sendbiz
                dashboard.
                <br />
                3.Communicate with candidates and schedule interviews directly
                through the platform.
                <br />
                4.Track the status of each application and manage your
                recruitment process efficiently.
                <br />
              </p>
            </Panel>
          </Collapse>
          <Collapse onChange={onChange} style={customPanelStyle}>
            <Panel
              header={
                <b>What should I do if I need help creating my company page?</b>
              }
              key="1"
            >
              <p>
                If you need assistance with creating your company page, you can
                access our support services
                <a href="https://urlz.fr/pHvE">here</a>. Our support team is
                available to guide you through each step and ensure your company
                page is set up correctly.
              </p>
            </Panel>
          </Collapse>
        </Col>
        <Col xs={24}>
          <ContactUs />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default AboutUs;
