import { Suspense, lazy } from "react";
import { Button, Col, Row } from "antd";
import Header from "../header/index.js";
import Banner1 from "../asset/business/consultingImg.png";
import greenTick from "../asset/home/greenTick.svg";
import banner2 from "../asset/business/banner2.png";
import banner3 from "../asset/business/banner3.png";
import "../business/style.css";
import Footer from "../footer/footer";
import { useHistory } from "react-router-dom";
import { ImageUrl } from "../../Shared/imageUrlPath.js";
const ConsultingComp2 = lazy(() => import("./comp2"));

function Consulting() {
  const history = useHistory();
  return (
    <>
      <Header />
      <Row className="container_img">
        <Col xs={24} className="container">
          <Row>
            <Col xs={24} sm={12}>
              <Col xs={24}>
                <h1 className="heading">
                  Connect with Top Talent through{" "}
                  <span className="subText">Sendbiz</span> Consulting
                </h1>
                <p className="para1">Seamless Talent Matching</p>
              </Col>
              <Col xs={24} className="flex">
                <span className="tick">
                  {" "}
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Effortlessly connect your projects with the right
                  professionals through our intuitive consulting platform.
                </p>
              </Col>
              <Col xs={24} className="flex">
                <span className="tick">
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Simplify the recruitment process with our easy-to-use
                  interface.
                </p>
              </Col>
              <Col xs={24} className="flex">
                <span className="tick">
                  {" "}
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Match with candidates who perfectly fit your project
                  requirements.
                </p>
              </Col>
              <Col xs={24} className="flex">
                <span className="tick">
                  {" "}
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Save time and resources by finding the right talent quickly.
                </p>
              </Col>
              <Col xs={24} className="btn-col">
                <Button
                  className="btn-start"
                  onClick={() => {
                    history.push("/signup");
                  }}
                >
                  Start for Free
                </Button>
              </Col>
            </Col>
            <Col xs={0} sm={1} />
            <Col xs={24} sm={11}>
              <div className="imageContainerHome">
                <img src={Banner1} className="image2" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className="containerStrip">
          <Row>
            <Col xs={24} sm={14}>
              <Row className="rowBox">
                <Col className="marginResp">
                  <img src={banner2} alt="banner2" />
                </Col>
                <Col xs={1} />
                <Col className="marginResp">
                  <div className="textBox1">
                    <p className="paraBox1">A worldwide business directory</p>
                  </div>
                </Col>
                <Col xs={1} />
                <Col className="marginResp">
                  <img src={banner3} alt="banner3" />
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="paraContain2">
                With Sendbiz, you can get discovered by new customers for free!
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Suspense fallback={<div>Loading...</div>}>
        <ConsultingComp2 />
      </Suspense>
      <Row className="aboutContainner">
        <Col xs={24}>
          <video
            controls
            poster={ImageUrl.imageUrlPath + "about-us/businessVidCover2.png"}
            className="landing-page-videodimension "
          >
            <source
              src={ImageUrl.imageUrlPath + "about-us/consultingVid.mp4"}
              type="video/mp4"
            />
          </video>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
export default Consulting;
